import Swiper, {Autoplay, EffectFade, HashNavigation, Keyboard, Manipulation, Mousewheel, Navigation} from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';


export const Carousel = () => {
    const slider = document.querySelector(".swiper-container");
    if (slider) {
        const swiperH = new Swiper('.swiper-container', {
            modules: [Navigation, Manipulation, EffectFade, Mousewheel, Autoplay, Keyboard, HashNavigation,],
            cssMode: false,
            direction: 'horizontal',
            followFinger: true,
            preloadImages: false,
            allowTouchMove: true,
            loop: true,
            effect: 'slide',
            watchSlidesProgress: true,
            speed: 100,
            on: {
                init: function () {
                },
                slideChangeTransitionStart: function () {
                },
                resize: function () {
                    if (window.innerWidth > 1024) {
                        this.slideTo(0, 0);
                        this.disable();
                    } else {
                        this.slideTo(1, 0);
                        this.enable();
                    }

                },

            },
            breakpoints: {
                // when window width is >= 320px
                1024: {
                    direction: 'vertical',
                    centeredSlides: true,
                },
            },
        });
    }
}


Carousel();