import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';


const lightbox = new PhotoSwipeLightbox({
    gallery: '#gallery--getting-started',
    children: 'a',
    initialZoomLevel: 'fill',
    secondaryZoomLevel: 'fill',
    maxZoomLevel: 1,
    escKey: true,
    pswpModule: () => import('photoswipe')
});
lightbox.init();


lightbox.on('change', () => {
    // triggers when slide is switched, and at initialization
    console.log('change');
});