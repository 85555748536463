document.addEventListener('DOMContentLoaded', function () {

    var YouTubeIframeLoader = require('youtube-iframe');
    const videoTrigger = document.querySelector('.video-trigger');
    const footerVideoContainer = document.querySelector('.footer-video-container');
    var player;

    videoTrigger?.addEventListener('click', (e) => {
        e.preventDefault();
        footerVideoContainer.classList.add('active');
    });

    var player;

    // YouTube Player API ready callback
    function onYouTubeIframeAPIReady() {
        // Create YouTube player instance
        player = new YT.Player('player', {
            height: '1080',
            width: '1920',
            videoId: 'dnciEvyIE_A',
            suggestedQuality: 'hd720',
            playerVars: {
                autoplay: 0,
                controls: 0,
                playsinline: 1,
                rel: 0,
                loop: 1,
                mute: 1,
                showinfo: 0,
            },
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onStateChange,
            }
        });

        // Play/Pause button click event handler
    }

    // Player ready event handler
    function onPlayerReady(event) {
        console.log('player is ready');


        var playPauseBtn = document.getElementById('playPauseBtn');
        playPauseBtn.addEventListener('click', function () {
            if (player.getPlayerState() === YT.PlayerState.PLAYING) {
                playPauseBtn.innerHTML = "Play Video";
                player.pauseVideo();
            } else {
                player.playVideo();
                playPauseBtn.innerHTML = "Pause Video"
            }
        });

        // Mute/Unmute button click event handler
        var muteBtn = document.getElementById('muteBtn');
        muteBtn.addEventListener('click', function () {
            player.mute();
            this.classList.add('active');
            unmuteBtn.classList.remove('active');
        });

        var unmuteBtn = document.getElementById('unmuteBtn');
        unmuteBtn.addEventListener('click', function () {
            if (player.isMuted()) {
                player.unMute();
                this.classList.add('active');
                muteBtn.classList.remove('active');
            }
        });
    }
    function restartVideo() {
        player.seekTo(0);
    }
    function onStateChange(event) {
        if (event.data === YT.PlayerState.ENDED) {
            restartVideo();
        }
    }
});


