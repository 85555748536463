const menuToggler = document.querySelector('.menu-toggler');
const menuCloser = document.querySelector('.menu-closer');
const navInner = document.querySelector('.nav-inner');
const nav = document.querySelector('nav');
const emptyArea = document.querySelectorAll('.submenu-empty-area');
const mainNavItems = document.querySelectorAll('nav .menu-item')

menuToggler.addEventListener("click", function (){
  nav.classList.add('menu-is-active');
})

menuCloser.addEventListener("click", function (){
  nav.classList.remove('menu-is-active');
  CloseAllMenus();
})

window.addEventListener("resize", function (){
  nav.classList.remove('menu-is-active');
  CloseAllMenus();
});


mainNavItems.forEach((item) =>{
  const submenu = item.querySelector('.submenu');
  const emptyArea = submenu?.querySelector('.submenu-empty-area');

  item.addEventListener('mouseenter', ()=>{
    submenu?.classList.add('opened');
  });
  item.addEventListener('mouseleave', ()=>{
    submenu?.classList.remove('opened');
  });
  emptyArea?.addEventListener('mouseenter', ()=>{
    submenu?.classList.remove('opened');
  });


});



const colls = document.querySelectorAll(".collapsible");

colls.forEach((coll) => {
  coll.addEventListener("click", function() {
    CloseAllMenus();
    this.classList.toggle("active");
    var content = this.nextElementSibling;
    if (content.style.maxHeight){
      content.style.maxHeight = null;
      content.classList.remove("is-opened");
    } else {
      content.classList.add("is-opened");
      content.style.maxHeight = content.scrollHeight + "px";
    }
  });
});

const CloseAllMenus = () => {
  colls.forEach((coll) => {
    var content = coll.nextElementSibling;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
      content.classList.remove("is-opened");
    }
  });
}