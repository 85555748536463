var toggleOptions = {
    "toggle": {
        "styles": {
            "button": {
                "background-color": "#000000",
                "color": "#ffffff",
                "padding": "10px 20px",
                "border-radius": "4px",
                "cursor": "pointer",
                "text-align": "center"
            },
            "label": {
                "color": "#000000",
                "font-size": "14px",
                "font-weight": "bold",
                "margin-right": "10px"
            },
            "container": {
                "margin-bottom": "20px"
            }
        },
        "text": {
            "label": "Toggle Text"
        },
        "isCollapsed": false // Set to true if you want the toggle component to be initially collapsed
    }
};


const lang = document.documentElement.getAttribute('lang');
switch (lang) {
    case 'en':
        var cart = "Cart";
        var AddToCart = 'Add to cart';
        var outOfStock = 'Out of Stock';
        var emptyCart = 'Your cart is empty.';
        var selectAnOption = "Selectionnez une option";
        var Checkout = "Check Out";
        var Subtotal = "SUBTOTAL";
        var notice = 'Shipping and discount codes are added at checkout.';
        var selectAnotherOption = "Please select another option";

        break;
    case 'fr':
        var cart = "Panier";
        var selectAnOption = "Select an Option";
        var AddToCart = 'Ajouter au panier';
        var outOfStock = 'Rupture de stock';
        var emptyCart = 'Votre panier est vide';
        var Checkout = "Paiement";
        var Subtotal = "TOTAL";
        var notice = 'Les frais de livraisons et réductions seront appliqués au paiement.';
        var selectAnotherOption = "Veuillez choisir une autre option";
        break;
}

const Shop = {
    scriptURL: 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js',
    productOptions: {
        "product": {
            iframe: false,
            events: {
                afterInit: function () {
                    console.log(window.hasStock+'windowstock');
// Ajouter l'option de sélection par défaut au début du menu déroulant
                },
                afterRender: _ => {
                    const title = document.querySelector('.shopify-buy__product__title');
                    const titleContent = title.innerHTML;
                    title.innerHTML = titleContent.replace(/^item\d+\s+/i, "");

                    const dropdowns = document.querySelectorAll('.shopify-buy__option-select__select');
                    const buyButton = document.querySelector('.shopify-buy__btn');
                    const buyButtonWrapper = document.querySelector('.shopify-buy__btn-wrapper');
                    const alert = document.createElement('div');

                    if (buyButton.classList.contains('shopify-buy__btn-disabled')) {
                        alert.classList.add('alert');
                        alert.innerHTML = selectAnotherOption;
                        buyButton.parentNode.insertBefore(alert, buyButton.nextSibling);
                    }

                    dropdowns.forEach((dropdown) => {
                        dropdown.addEventListener("change", () => {
                            setTimeout(function () {
                                if (buyButton.classList.contains('shopify-buy__btn-disabled')) {
                                    if (!alert) {
                                        const alert = document.createElement('div');
                                        alert.classList.add('alert');
                                        alert.innerHTML = selectAnotherOption;
                                        buyButton.parentNode.insertBefore(alert, buyButton.nextSibling);
                                    }
                                } else {
                                    alert.innerHTML = null;
                                }
                            }, 100);

                        });
                    });

                    if (window.hasStock === false) {
                        const targetElement = document.querySelector('.shopify-buy__product__variant-selectors');
                        targetElement.style.display = 'none';
                        alert.remove();
                    }


                }
            },
            text: {
                button: AddToCart,
                outOfStock: outOfStock,
                currency: '€',
            },
            contents: {
                img: false,
                title: true,
                imgWithCarousel: false,
                variantTitle: false,
                description: true,
                options: true,
                buttonWithQuantity: false,
                quantity: false,
                price: true,
                priceWithDiscounts: true,
            },
            translations: {
                option_labels: {
                    taille: {
                        en: 'Size',
                        fr: 'Taille',
                    },
                    couleur: {
                        en: 'Color',
                        fr: 'Couleur',
                    },
                },
            },
            order: [
                'title',
                'variantTitle',
                'price',
                'buttonWithQuantity',
                'description',
                'options',
                'quantity',
                'button',
            ],
            "templates": {
                "title": '<h2 class="{{data.classes.product.title}}">{{data.title}}</h2>',
            },
        },
    },
    cartOptions: {
        "cart": {
            iframe: true,
            startOpen: false,
            node: document.getElementById('bouton-achat'),
            popup: false,
            manifest: ['cart', 'lineItem', 'toggle'],
            contents: {
                title: true,
                lineItems: true,
                footer: true,
                note: false,
                discounts: true,
            },
            text: {
                title: cart,
                empty: emptyCart,
                button: Checkout,
                total: 'SUBTOTAL',
                currency: '€',
                notice: notice,
            },
            events: {
                updateItemQuantity: function (cart) {
                },
            },
        },
        "lineItem": {},
        "toggle": {
            iframe: false,
            "contents": {
                "title": true,
                "icon": false
            },
            "text": {
                title: cart,
                empty: emptyCart,
            },
        },
        "modalProduct": {
            "contents": {
                "img": false,
                "imgWithCarousel": true,
                "variantTitle": false,
                "buttonWithQuantity": true,
                "button": false,
                "quantity": false
            },
        },
        "productSet": {},
    },
    init: _ => {
        if (window.ShopifyBuy) {
            if (window.ShopifyBuy.UI) {
                Shop.ShopifyBuyInit();
            } else {
                loadScript();
            }
        } else {
            loadScript();
        }

        function loadScript() {
            var script = document.createElement('script');
            script.async = true;
            script.src = Shop.scriptURL;
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
            script.onload = Shop.ShopifyBuyInit;
        }
    },
    ShopifyBuyInit: _ => {
        Shop.client = ShopifyBuy.buildClient({
            domain: 'shop.crestclothing.com',
            storefrontAccessToken: '092e3849a130c59a393b725877273b9d',
            appId: '6',
        });

        const cart = document.getElementById("cart");
        if (cart) {
            Shop.createCart(cart);
        }

        const items = document.querySelectorAll('[data-product]')
        items.forEach(el => {
            Shop.createButton(el.dataset.product)
        });

    },
    createButton: (id, productData) => {
        const node = document.querySelector('.detailed-product--grid');
        ShopifyBuy.UI.onReady(Shop.client).then(function (ui) {
            var pId = "gid://shopify/Product/" + id;

            var availableVariantFound = false;
            var availableVariantColor;
            var variants = [];

            Shop.client.product.fetch(pId).then(product => {
                // Handle the list of products



                product.variants.forEach(variant => {
                    // For each variant, you can access the inventory quantity
                    if(variant.available && availableVariantFound == false){
                        availableVariantFound = variant.id.replace(/\D/g, "");
                        availableVariantColor = variant;
                        return;
                    }

                });

               /* const filteredVariants = variants.filter(variant => {
                    // Check if the variant has SelectedOptions
/!*
                    return variant.selectedOptions.some(option => option.name === "Color" && option.value === "Royal Blue");
*!/

                });
*/
                console.log(variants);

                window.hasStock = product.variants.some(variant => variant.available);

                var productComponent = ui.createComponent('product', {
                    id: [id],
                    variantId: availableVariantFound,
                    node: node,
                    options: Shop.productOptions,
                    moneyFormat: '%E2%82%AC%7B%7Bamount%7D%7D',
                });


                // Create an object to store variants grouped by option

/*// Iterate through the product variants
                const variantsByFirstPart = {};

                product.variants.forEach(variant => {
                    // Split the variant title by "/"
                    const titleParts = variant.title.split(' / ');

                    if (titleParts.length >= 2) {
                        const firstPart = titleParts[0];
                        const secondPart = titleParts[1];

                        if (!variantsByFirstPart[firstPart]) {
                            // If the first part does not exist in the variantsByFirstPart object, create it as an empty array
                            variantsByFirstPart[firstPart] = [];
                        }

                        // Push the variant to the corresponding first part in the variantsByFirstPart object
                        variantsByFirstPart[firstPart].push({
                            id: variant.id,
                            secondPart: secondPart,
                            price: variant.price,
                        });
                    }
                });*/


            }).catch(error => {
                console.error(error);
            });
        });
    },
    createCart: cart => {
        ShopifyBuy.UI.onReady(Shop.client).then(function (ui) {
            ui.createComponent('cart', {
                node: cart.querySelector('#cart-container'), // Update this to the ID or DOM element of the desired node for the cart
                options: Shop.cartOptions,
                moneyFormat: '€{{amount}}',
            });
        });
    },
}

export default Shop;
Shop.init();
